import React from 'react'
import Layout from "../components/layout";
import { Container } from 'reactstrap';

const NotFoundPage = () => (
  <Layout>
    <Container>
        <br/><br/>
        <h1>Page Not Found</h1>
        <br/>
        <p>The Saint John Energy website has been redesigned.</p>
        <br/><br/>
        <p>Please click <a href="/" title="Home">here</a> for our updated website's home page.</p>
        <br/><br/><br/><br/><br/>
    </Container>
  </Layout>
)

export default NotFoundPage
